
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import ImpressionTrackingMixin from '@/mixins/ImpressionTrackingMixin.vue';
import TitleWatchlistMutationMixin from '@/mixins/title-quick-action-mutations/TitleWatchlistMutationMixin.vue';

import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import ImpressionPixel from '@/components/sponsored-recommendations/ImpressionPixel.vue';
import RecommendationAdPill from '@/components/sponsored-recommendations/RecommendationAdPill.vue';
import SRTitleListGraphql from '@/components/SRTitleListGraphql.vue';
import ListToggle from '@/components/sponsored-recommendations/actions/ListToggle.vue';

import {
	SnowplowContext,
	SnowplowModuleContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';

import { ExternalTracker, ExternalTrackerType, SraEventAction } from '@/@types/graphql-types';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import type { SRClickTitleEvent, ListTitleSR } from '@/components/sponsored-recommendations/types';
import { ReadyType } from '@/stores/ready.store';
import {
	injectExternalHtmlTrackers,
	handleExternalClickoutTrackers,
	formatExternalTracker,
} from '@/helpers/impression-tracker-helper';
import { hasCCPABanner, hasUCConsentBanner } from '@/helpers/tracking';
import { WebLocale } from '@/enums/web-locale';
import { TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getSrPlacementFromBidId } from './useSponsoredRec';

const Language = namespace('language');
const TitleDetailsStore = namespace('titleDetails');
const User = namespace('user');

@Component<SponsoredRecommendationList>({
	name: 'SponsoredRecommendationList',
	components: {
		ImpressionPixel,
		ProviderIcon,
		RecommendationAdPill,
		SRTitleListGraphql,
		ListToggle,
	},
})
export default class SponsoredRecommendationList extends Mixins(
	BackendTrackingMixin,
	ImpressionTrackingMixin,
	TitleWatchlistMutationMixin
) {
	impressionPixelsInternal: Array<string> = [];
	impressionPixelsExternal: Array<string> = [];

	@Prop({ default: () => [] }) declare additionalContexts: (SnowplowModuleContext | SnowplowContext)[];
	@Prop({ required: true }) declare sponsoredAd: SponsoredAdFragment;
	@Prop({ default: true }) lazy: boolean;
	@Prop({ default: false }) fullWidth: boolean;

	@Language.Getter declare language: string;
	@Language.State webLocale: WebLocale;

	@TitleDetailsStore.Action setSponsoredRecommendation: (sponsoredAd: SponsoredAdFragment) => void;

	@User.Getter strictlyAllConsentsGranted: boolean;

	/** IMPRESSION TRACKING */
	get observable() {
		return this.getObservableOptions(this.campaignNode.id);
	}
	/** IMPRESSION TRACKING */

	get campaignNode() {
		return this.sponsoredAd.campaign!.node as ListTitleSR;
	}

	get promotionalTitle() {
		return this.sponsoredAd.campaign?.promotionalTitle || this.campaignNode.content.name;
	}

	get promotionalProviderLogo() {
		return this.sponsoredAd.campaign?.promotionalProviderLogo;
	}

	get titles() {
		return this.campaignNode.titles.edges?.map(edge => edge.node) ?? [];
	}

	get impressionTagInternal() {
		return '';
	}

	get impressionTagExternal() {
		return '';
	}

	get providerId() {
		return this.sponsoredAd.campaign?.watchNowOffer.package.packageId;
	}

	mounted() {
		this.injectExternalImpressionHtml();
		this.sendAdRenderedEvent();
	}

	activated() {
		this.sendAdRenderedEvent();
	}

	// needed by ImpressionTrackingMixin
	impressionCallback() {
		this.$backendTracking.trackSraEvent(SraEventAction.Impression, this.sponsoredAd);

		this.externalTrackingImpressionUrl();

		if (this.impressionTagInternal) this.addImpressionTrackingPixelInternal();
		if (this.impressionTagExternal) this.addImpressionTrackingPixelExternal();
	}

	sendAdRenderedEvent() {
		const properties: TrackingProviderPropertiesInterface = { action: 'ad_render', nonInteraction: true };
		if (this.impressionTrackingLabel) properties.label = this.impressionTrackingLabel;
		if (this.impressionTrackingProperty) properties.property = this.impressionTrackingProperty;
		if (this.impressionTrackingValue) properties.value = this.impressionTrackingValue;

		const contexts = [...this.additionalContexts];
		if (this.titleObjectId && this.titleObjectType) {
			contexts.push(new SnowplowTitleContextGraphql(this.titleObjectId, this.titleObjectType));
		}

		TrackingHelper.trackEvent('sponsored_recommendations', properties, contexts);
		this.$backendTracking.trackSraEvent(SraEventAction.AdRender, this.sponsoredAd);
	}

	get landingPageURL(): string {
		return formatExternalTracker({
			tracker: this.sponsoredAd.campaign?.watchNowOffer.standardWebURL || '',
			webLocale: this.webLocale,
			placement: getSrPlacementFromBidId(this.sponsoredAd.bidId),
			campaignName: this.sponsoredAd.campaign?.name,
		});
	}

	async externalTrackingClickoutUrl() {
		if (this.externalClickoutUrl.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalClickoutUrl,
				trackerType: 'external_clickout_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}

	get externalClickoutUrl(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ClickoutUrl
		);
	}

	get externalImpressionUrl(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionUrl
		);
	}

	get externalImpressionHtml(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionHtml
		);
	}

	get externalClickout() {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.find(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ClickoutUrl
		);
	}

	onSRListPosterClickedOut({ title, index }: { title: ListTitleSR; index: number }) {
		this.$backendTracking.trackSraEvent(SraEventAction.Clickout, this.sponsoredAd, title.id, index);

		this.externalTrackingClickoutUrl();
	}

	onSRListPosterClicked({ title, index }: { title: ListTitleSR; index: number }) {
		this.setSponsoredRecommendation(this.sponsoredAd);

		this.$backendTracking.trackSraEvent(SraEventAction.Click, this.sponsoredAd, title.id, index);

		// pass down click event after title got clicked
		const clickTitleEvent: SRClickTitleEvent<ListTitleSR> = { title, index, titleList: this.campaignNode.id };
		this.$emit('click', clickTitleEvent);
	}

	onSRListClick() {
		this.$backendTracking.trackSraEvent(SraEventAction.Click, this.sponsoredAd);

		if (this.landingPageURL) {
			this.$backendTracking.trackSraEvent(SraEventAction.ListClickout, this.sponsoredAd);
		}

		this.externalTrackingClickoutUrl();
	}

	onFollowClicked() {
		this.$backendTracking.trackSraEvent(SraEventAction.FollowClicked, this.sponsoredAd);
	}

	async hasStrictlyAllConsentsGranted() {
		if (hasUCConsentBanner(this.webLocale) && !hasCCPABanner()) {
			await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
		}

		return true;
	}

	async externalTrackingImpressionUrl() {
		if (this.externalImpressionUrl.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalImpressionUrl,
				trackerType: 'external_impression_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}

	async injectExternalImpressionHtml() {
		if (this.externalImpressionHtml.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			injectExternalHtmlTrackers({
				externalTrackers: this.externalImpressionHtml,
				webLocale: this.webLocale,
				bidId: this.sponsoredAd.bidId,
			});
		}
	}

	addImpressionTrackingPixelInternal() {
		this.impressionPixelsInternal.push('_');
	}

	async addImpressionTrackingPixelExternal() {
		if (await this.hasStrictlyAllConsentsGranted()) {
			this.impressionPixelsExternal.push('_');
		}
	}
}
