
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import ImpressionTrackingMixin from '@/mixins/ImpressionTrackingMixin.vue';
import TitleWatchlistMutationMixin from '@/mixins/title-quick-action-mutations/TitleWatchlistMutationMixin.vue';

const MarkAsSeenModalGraphql = () => import('@/components/mark-as-seen/MarkAsSeenModalContainerGraphql.vue');
const TitleScoreList = () => import('@/components/TitleScoreList.vue');

import WatchNowButton from '@/components/buttons/WatchNowButton.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import ImpressionPixel from '@/components/sponsored-recommendations/ImpressionPixel.vue';
import RecommendationAdPill from '@/components/sponsored-recommendations/RecommendationAdPill.vue';

import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowModuleContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';

import { ExternalTracker, ExternalTrackerType, ObjectType, SraEventAction } from '@/@types/graphql-types';
import { ModalHelper } from '@/helpers/modal-helper';
import type { MarkAsSeenModalProps } from '@/components/mark-as-seen/MarkAsSeen';
import { MarkAsSeenModalOptions } from '@/components/mark-as-seen/MarkAsSeen';

import { ImpressionTrackingEvents } from '@/enums/events';

import { ClickoutUrl } from '@/helpers/clickout-helper';
import type { ListMutationTitleDetailParam } from '@/helpers/providers/title-actions-provider';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import type { SRClickTitleEvent, SingleTitleSR } from '@/components/sponsored-recommendations/types';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { ReadyType } from '@/stores/ready.store';
import { injectExternalHtmlTrackers, handleExternalClickoutTrackers } from '@/helpers/impression-tracker-helper';
import { WebLocale } from '@/enums/web-locale';
import { hasCCPABanner, hasUCConsentBanner } from '@/helpers/tracking';
import { TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';

const Language = namespace('language');
const TitleDetailsStore = namespace('titleDetails');
const User = namespace('user');

/** Viewing thresholds at which we want to track. */
type TrackingWatchedPoints = (typeof TrackingWatchedPoints)[number];
const TrackingWatchedPoints = [0, 25, 50, 75, 90, 100] as const;

@Component<SponsoredRecommendationVideo>({
	name: 'SponsoredRecommendationVideo',
	components: {
		ImpressionPixel,
		ProviderIcon,
		RecommendationAdPill,
		TitleScoreList,
		WatchNowButton,
	},
})
export default class SponsoredRecommendationVideo extends Mixins(
	BackendTrackingMixin,
	ImpressionTrackingMixin,
	TitleWatchlistMutationMixin
) {
	impressionPixelsInternal: Array<string> = [];
	impressionPixelsExternal: Array<string> = [];
	isFading = false;
	isMuted = true;

	@Ref('videoTag') readonly videoTag!: HTMLVideoElement;

	@Prop({ default: () => [] }) declare additionalContexts: (SnowplowModuleContext | SnowplowContext)[];
	@Prop({ required: true }) declare sponsoredAd: SponsoredAdFragment;
	@Prop({ default: true }) lazy: boolean;
	@Prop({ default: () => [] }) supportedObjectTypes: ObjectType[];

	@Language.Getter declare language: string;
	@Language.State webLocale: WebLocale;

	@TitleDetailsStore.Action setSponsoredRecommendation: (sponsoredAd: SponsoredAdFragment) => void;

	@User.Getter strictlyAllConsentsGranted: boolean;

	@Watch('isLoggedIn', { immediate: true })
	onIsLoggedInChange() {
		const { id, details } = this.loginStateTitleDetails;
		if (this.isLoggedIn()) {
			this.mixin_onIsLoggedInChange(
				id,
				details,
				this.isInWatchlist,
				ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS
			);
		}
	}

	/** IMPRESSION TRACKING */
	get observable() {
		return this.getObservableOptions(this.campaignNode.nodeId);
	}
	/** IMPRESSION TRACKING */

	get loginStateTitleDetails() {
		return {
			id: this.campaignNode.nodeId,
			details: {
				title: this.campaignNode.content.title,
				objectType: this.campaignNode.objectType,
				objectId: this.campaignNode.objectId,
			},
		};
	}

	get isInWatchlist() {
		if (this.campaignNode?.__typename === 'Season') {
			return !!this.campaignNode?.show.watchlistEntryV2?.createdAt;
		}

		return !!this.campaignNode?.watchlistEntryV2?.createdAt;
	}

	get promotionalImageUrl() {
		return this.sponsoredAd.campaign?.promotionalImageUrl;
	}

	get promotionalTitle(): string {
		if (this.sponsoredAd.campaign?.promotionalTitle) {
			return this.sponsoredAd.campaign?.promotionalTitle;
		}

		const campaignTitle = this.campaignNode.content.title;

		if (this.campaignNode?.__typename === 'Season') {
			return `${this.campaignNode.show.content.originalTitle} - ${campaignTitle}`;
		}

		return campaignTitle;
	}

	get getTitleSize(): 'regular' | 'medium' | 'small' {
		const titleLength = this.promotionalTitle?.length ?? 0;

		if (titleLength >= 22 && titleLength < 32) return 'medium';
		if (titleLength >= 32) return 'small';
		return 'regular';
	}

	get promotionalText() {
		return this.sponsoredAd.campaign?.promotionalText || this.campaignNode.content.originalReleaseYear;
	}

	get promotionalProviderLogo() {
		return this.sponsoredAd.campaign?.promotionalProviderLogo;
	}

	get posterKey() {
		return this.promotionalImageUrl || this.campaignNode.objectId;
	}

	get campaignNode() {
		return this.sponsoredAd.campaign!.node as SingleTitleSR;
	}

	get volumeIcon() {
		const filename = this.isMuted ? 'volume-xmark' : 'volume-high';
		return `/${ASSETS_DIR}/img/sponsored-recommendation-assets/${filename}.svg`;
	}

	get watchNowLabel() {
		return this.sponsoredAd.campaign?.watchNowLabel;
	}

	get impressionTagInternal() {
		return '';
	}

	get impressionTagExternal() {
		return '';
	}

	get providerId() {
		return this.sponsoredAd.campaign?.watchNowOffer.package.packageId;
	}

	get seenEpisodeCount() {
		return 'seenState' in this.campaignNode ? this.campaignNode.seenState?.seenEpisodeCount : null;
	}

	get watchNowOffer() {
		return this.sponsoredAd.campaign?.watchNowOffer;
	}

	get titleContext() {
		let seasonNumber;
		if (this.campaignNode?.__typename === 'Season') {
			seasonNumber = this.campaignNode.content.seasonNumber;
		}

		return new SnowplowTitleContextGraphql(this.campaignNode.objectId, this.campaignNode.objectType, seasonNumber);
	}

	get clickoutContext() {
		if (!this.watchNowOffer) return;

		return SnowplowClickoutContextGraphql.fromProviderOffer(this.watchNowOffer);
	}

	// Hack: add `uct_sr` for The Supremes campaign
	// Campaign Video:  THE_SUPREMES_AT_EARLS_ALL-YOU-CAN-EAT_BURST1_US_SRA_2
	get hasUctSrParam() {
		if (!this.sponsoredAd?.campaign) return;

		const regex = new RegExp(/THE_SUPREMES_AT_EARLS_ALL-YOU-CAN-EAT_BURST\d_US_SRA(.+)?/);
		return regex.test(this.sponsoredAd.campaign?.name);
	}

	get watchNowUctLink() {
		return new ClickoutUrl(this.watchNowOffer)
			.set('uct_country', this.country)
			.set('uct_sr', this.hasUctSrParam ? 'true' : 'false')
			.set('uct_tryout', 1)
			.toString();
	}

	/**
	 * Disney's Title Page hack
	 * We want to link to our Title Page,
	 * instead of taking the user to an external page
	 *
	 * Hack rule:
	 * For all bursts for this campaign in IT for the
	 * KINGDOM_OF_THE_PLANET_OF_THE_APES camapigns
	 *
	 * @returns {string | null}
	 */
	get linkToTitlePage(): string | null {
		if (!this.sponsoredAd?.campaign) return null;

		const regex = new RegExp(/KINGDOM_OF_THE_PLANET_OF_THE_APES_BURST\d_IT_SRA/);

		if (regex.test(this.sponsoredAd.campaign?.name)) {
			return this.campaignNode.content.fullPath;
		}

		return null;
	}

	mounted() {
		this.injectExternalImpressionHtml();
		this.sendAdRenderedEvent();
	}

	activated() {
		this.sendAdRenderedEvent();
	}

	/* VIDEO WATCH TRACKING */
	videoDuration = 0;
	lastTrackedPoint: TrackingWatchedPoints | -1 = -1;

	setVideoDuration() {
		this.videoDuration = this.videoTag.duration;
	}

	onTimeUpdate() {
		if (this.lastTrackedPoint >= 100) return;

		const percentageViewed = Math.ceil((this.videoTag.currentTime / this.videoDuration) * 100);
		const nextTrackingPoint = TrackingWatchedPoints.find(point => point > this.lastTrackedPoint);

		if (nextTrackingPoint == null || percentageViewed < nextTrackingPoint) return;
		this.trackVideoView(nextTrackingPoint);
	}

	/**
	 * `this.onTimeUpdate` doesn't always fire at 100 before restarting the loop.
	 * So we trigger it manually to guarantee it gets tracked.
	 */
	onVideoEnded() {
		this.videoTag.play();
	}

	trackVideoView(pointReached: TrackingWatchedPoints) {
		this.lastTrackedPoint = pointReached;

		this.$backendTracking.trackSraEvent(
			SraEventAction.VideoView,
			this.sponsoredAd,
			undefined,
			undefined,
			pointReached
		);
	}

	// needed by ImpressionTrackingMixin
	impressionCallback() {
		this.$backendTracking.trackSraEvent(SraEventAction.Impression, this.sponsoredAd);

		// External tracking
		this.externalTrackingImpressionUrl();

		if (this.impressionTagInternal) this.addImpressionTrackingPixelInternal();
		if (this.impressionTagExternal) this.addImpressionTrackingPixelExternal();
	}

	sendAdRenderedEvent() {
		const properties: TrackingProviderPropertiesInterface = { action: 'ad_render', nonInteraction: true };
		if (this.impressionTrackingLabel) properties.label = this.impressionTrackingLabel;
		if (this.impressionTrackingProperty) properties.property = this.impressionTrackingProperty;
		if (this.impressionTrackingValue) properties.value = this.impressionTrackingValue;

		const contexts = [...this.additionalContexts];
		if (this.titleObjectId && this.titleObjectType) {
			contexts.push(new SnowplowTitleContextGraphql(this.titleObjectId, this.titleObjectType));
		}

		TrackingHelper.trackEvent('sponsored_recommendations', properties, contexts);
		this.$backendTracking.trackSraEvent(SraEventAction.AdRender, this.sponsoredAd);
	}

	onVisibilityChanged(isVisible: boolean) {
		if (isVisible) {
			this.videoTag.play();
		} else {
			this.videoTag.pause();
		}
	}

	watchNowClicked() {
		this.$backendTracking.trackSraEvent(SraEventAction.Click, this.sponsoredAd);
		this.$backendTracking.trackSraEvent(SraEventAction.WatchnowClicked, this.sponsoredAd);

		// External tracking
		this.externalTrackingClickoutUrl();
	}

	// External tracking
	get externalClickoutUrl(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ClickoutUrl
		);
	}

	async externalTrackingClickoutUrl() {
		if (this.externalClickoutUrl.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalClickoutUrl,
				trackerType: 'external_clickout_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}

	get externalImpressionUrl(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionUrl
		);
	}

	get externalImpressionHtml(): ExternalTracker[] {
		if (!this.sponsoredAd?.campaign) return [];

		return this.sponsoredAd.campaign.externalTrackers.filter(
			(e: ExternalTracker) => e.type === ExternalTrackerType.ImpressionHtml
		);
	}

	get trailerUrl() {
		return this.sponsoredAd.campaign?.promotionalVideo?.url;
	}

	async toggleWatchlist(title: typeof this.campaignNode) {
		this.$backendTracking.trackSraEvent(SraEventAction.WatchlistClicked, this.sponsoredAd);

		const titleDetails: ListMutationTitleDetailParam = {
			title: title.content.title,
			objectType: title.objectType,
			objectId: title.objectId,
		};
		const trackingPayload = {
			source: 'sponsored-recommendations',
		};
		const updatedTitle = await this.mixin_setInWatchlist(
			title.nodeId,
			this.isInWatchlist,
			titleDetails,
			trackingPayload
		);
		if (updatedTitle) {
			const isInWatchlist = !!updatedTitle.data?.setInWatchlistV2.title.watchlistEntryV2?.createdAt;
			if (
				isInWatchlist &&
				(title.__typename === 'Show' || title.__typename === 'Season') &&
				title.content.isReleased
			) {
				ModalHelper.openModal(
					MarkAsSeenModalGraphql,
					{
						titleId: title.nodeId,
						titleName: title.content.title,
						titleObjectId: title.objectId,
						titleObjectType: title.objectType,
						titleContentType: undefined,
						posterUrl: title.content.posterUrl,
						backdrops: title.content.backdrops,
						seenEpisodeCount: this.seenEpisodeCount,
					} as MarkAsSeenModalProps,
					MarkAsSeenModalOptions,
					'',
					false,
					this.additionalContexts
				);
			}
		}
	}

	onPosterClick() {
		this.setSponsoredRecommendation(this.sponsoredAd);

		this.$backendTracking.trackSraEvent(SraEventAction.Click, this.sponsoredAd);
		this.$backendTracking.trackSraEvent(SraEventAction.Clickout, this.sponsoredAd);

		// make sure the video is muted again, so it doesn't continue to play in the background
		this.isMuted = true;

		// External tracking
		this.externalTrackingClickoutUrl();

		// pass down poster-clicked event as 'click'
		const clickTitleEvent: SRClickTitleEvent<SingleTitleSR> = { title: this.campaignNode, index: 0 };
		this.$emit('click', clickTitleEvent);
	}

	toggleMute() {
		this.isMuted = !this.isMuted;
		this.videoTag.muted = this.isMuted;

		// legacy event as long as there doesnt exist a SraEventAction.ToggleMute.
		TrackingHelper.trackEvent(
			this.impressionTrackingCategory,
			{
				action: 'toggle_mute',
				value: this.isMuted ? 1 : 0,
			},
			[this.titleContext]
		);
	}

	onMoreInfoClick() {
		this.setSponsoredRecommendation(this.sponsoredAd);

		this.$backendTracking.trackSraEvent(SraEventAction.MoreInfoClicked, this.sponsoredAd);

		// navigate to title detail page
		const clickTitleEvent: SRClickTitleEvent<SingleTitleSR> = { title: this.campaignNode, index: 0 };
		this.$emit('navigateTitle', clickTitleEvent);
	}

	onVideoStateChanged(eventName: string) {
		if (eventName !== 'play') return;
		setTimeout(() => (this.isFading = true), 1500);
	}

	onMouseEnter() {
		this.isFading = false;
	}

	onMouseLeave() {
		this.isFading = true;
	}

	async injectExternalImpressionHtml() {
		if (this.externalImpressionHtml.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			injectExternalHtmlTrackers({
				externalTrackers: this.externalImpressionHtml,
				webLocale: this.webLocale,
				bidId: this.sponsoredAd.bidId,
			});
		}
	}

	addImpressionTrackingPixelInternal() {
		this.impressionPixelsInternal.push('_');
	}

	async addImpressionTrackingPixelExternal() {
		if (await this.hasStrictlyAllConsentsGranted()) {
			this.impressionPixelsExternal.push('_');
		}
	}

	async hasStrictlyAllConsentsGranted() {
		if (hasUCConsentBanner(this.webLocale) && !hasCCPABanner()) {
			await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
		}

		return true;
		// return this.strictlyAllConsentsGranted;
	}

	async externalTrackingImpressionUrl() {
		if (this.externalImpressionUrl.length === 0) return;

		if (await this.hasStrictlyAllConsentsGranted()) {
			handleExternalClickoutTrackers({
				externalTrackers: this.externalImpressionUrl,
				trackerType: 'external_impression_tracker_fired',
				bidId: this.sponsoredAd.bidId,
				webLocale: this.webLocale,
			});
		}
	}
}
